var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('centered-column-layout',[_c('div',{staticClass:"mt-5 mb-5 flex flex-col sm:flex-row justify-between gap-2"},[_c('text-input',{staticClass:"w-full sm:w-1/2 mt-0 mb-0 pt-0 pb-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': this,
            'key': 'articleTitle',
            'titleVisible': false,
            'placeholder': _vm.$t('article_title_input_placeholder')
          },
        }}}),_c('a-button',{staticClass:"btn-success self-start",attrs:{"disabled":_vm.disabledSaveButton},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('save_button'))+" ")])],1),_c('div',[_c('a-card',{staticClass:"w-full"},[_c('div',{ref:"editor",attrs:{"id":"editorjs"}})])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }